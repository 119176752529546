<template>
  <keep-alive :include="keepAlivesComponents">
    <router-view />
  </keep-alive>
</template>

<script>
export default {
  name: "SpeakerPresentation",
  data() {
    return {
      keepAlivesComponents: ["PresentationControl"],
    };
  },
};
</script>
